/* 公共函数 */


// 数字超过一万，以万为单位返回数字
export function getNumbers(num_) {
  if (num_ >= 10000) {
    // 千位下浮动,如：1.21万 -> 1.2万
    // return parseInt(num_/10000*10)/10+'万';
    // 千位上浮动,如：1.21万 -> 1.3万
    return Math.ceil(num_ / 1000) / 10 + 'w'
  } else {
    return num_
  }
}

// 学习进度百分比
export function percentage(total_, viewed_) {
  let percent = total_ === 0 ? 0 : (viewed_ / total_) * 100
  if (percent > 100) { // 容错处理
    percent = 100
  }

  // 保留小数点
  let y = String(percent).indexOf(".") + 1;//获取小数点的位置
  let count = String(percent).length - y;//获取小数点后的个数
  if (count > 2) {
    return percent.toFixed(2)
  } else {
    return percent
  }

}

// 文字超出增加...
export function cutStr(value, end) {
  if (value.length > end) {
    return value.substr(0, end) + '...'
  } else {
    return value
  }
}

// 图片地址前缀
export function prefixSrc(value, type) {
  if (value == 1) {
    return process.env.VUE_APP_IMG_API + "/common/team.png"
  }
  if (value) {
    if (value.indexOf('http') == -1) {
      if (type == 1) {
        return 'https://img.ke.yiihuu.com/'.concat(value)
      } else {
        return process.env.VUE_APP_IMG_API + '/'.concat(value)
      }
    }
    if (value.indexOf('api/uploads/') != -1 && value.indexOf('http') == -1) {
      return process.env.BASE_API + '/'.concat(value)
    } else {
      return value
    }

  }
}

// 头像
export function avatarSrc(value) {
  if (value) {
    if (value.indexOf('http') == -1) {
      return process.env.VUE_APP_IMG_API + '/'.concat(value)
    }
    if (value.indexOf('api/uploads/') != -1 && value.indexOf('http') == -1) {
      return process.env.BASE_API + '/'.concat(value)
    } else {
      return value
    }

  } else {
    return process.env.VUE_APP_IMG_API + "/common/student_boy.png"
  }
}

// 首页图片地址
export function homeSrc(value, type) {
  if (value.indexOf('http') == -1) {
    switch (type) {
      case 1:
        return `https://img12.yiihuu.com/upimg/manage/${value}`
      case 2:
        return `https://img12.yiihuu.com/upimg/courses/${value}`
      default:
        return `https://img12.yiihuu.com/upimg/album/${value}`
    }
  } else return value


}

// 日期转天数
export function leftDay(time) {
  var EndTime = new Date(time);
  var NowTime = new Date();
  var t = EndTime.getTime() - NowTime.getTime();
  var res =
    Math.ceil(t / 1000 / 60 / 60 / 24) > 0
      ? Math.ceil(t / 1000 / 60 / 60 / 24)
      : 0;
  return res;
}


// 下载图片
export async function downloadImage(imageUrl, name) {
  const image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    // 得到图片的base64编码数据
    const url = canvas.toDataURL('image/png');

    // 生成一个a元素
    const a = document.createElement('a');
    // 创建一个单击事件
    const event = new MouseEvent('click');
    a.download = name;
    // 将生成的URL设置为a.href属性
    a.href = url;
    // 触发a的单击事件
    a.dispatchEvent(event);
  };
  image.src = imageUrl;
}

// 文件预览
export async function readUrl(url) {
  return new Promise((resolve, reject) => {
    fetch(url).then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error(response.statusText);
      }
    }).then(blob => {
      // 创建一个 URL 指向 Blob 数据
      const imageObjectUrl = URL.createObjectURL(blob);

      // 创建一个 Image 对象并设置其 src 属性
      const img = new Image();
      img.src = imageObjectUrl;

      // 打印图片的尺寸等信息
      img.onload = () => {
        resolve(img);
        // 释放 Blob 数据对应的 URL
        URL.revokeObjectURL(imageObjectUrl);
      };
    }).catch(error => {
      reject(error)
      console.error('There has been a problem with your fetch operation:', error);
    });

  });
}