import request from '@/utils/request'
import yihuiRequest from '@/utils/yihuiRequest'
let jsapi = "/jsapi"
let domain = process.env.NODE_ENV == 'production' ? 'https://lib.yiihuu.com' : 'http://test.lib.yiihuu.com:81'
// 选手报名 
export function login(data) {
  return request({
    url: `${domain}${jsapi}/js-api.php`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

export function register(data) {
  return request({
    url: `${domain}${jsapi}/js-api.php`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

export function logout(data) {
  return request({
    url: `${domain}${jsapi}/login_out.php`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 个人信息
export const getPersonalInfo = (params) => request.get('/api/user/personal_info', { params })


// 新首页接口
export const getIndexNew = (params) => request.get('https://lib-api.yiihuu.com/api/index_new', { params })
// export const getIndexNew = (params) => request.get('/api/index_new', { params })

// 专辑推荐
export const getIndexRecommend = (params) => request.get('https://lib-api.yiihuu.com/api/index_recommend', { params })

// 学习路径
export const getIndexLearnpath = (params) => request.get('https://lib-api.yiihuu.com/api/index_learnpath', { params })

// 学员作品
export const getIndexStdworks = (params) => request.get('/api/index_stdworks', { params })

// 在线设计
export const getIndexOnlinedsn = (params) => request.get('https://lib-api.yiihuu.com/api/index_onlinedsn', { params })

// 图文资讯
export const getIndexOnTwzx = (params) => request.get('https://lib-api.yiihuu.com/api/index_twzx', { params })

// 专题推荐
export const getIndexSubject = (params) => request.get('/api/index_subject', { params })

// 图文资讯
export const getIsIpBelong = (params) => request.get('/api/is_ip_belong', { params })

// 头部模块
export const getModulePermission = (params) => request.get('https://lib-api.yiihuu.com/api/get_module_permission', { params })

// 限制弹框
export const getDesignInfo = (params) => request.get('https://lib-api.yiihuu.com/api/get_design_info', { params })

// 获奖弹框
export const getLibWorkPriceLog = (params) => request.get('/api/activity/get_lib_work_price_log', { params })

// 获取用户ip
export const getIp = (params) => request.get('/api/get_ip', { params })

// 在线上报
export const viewLogReport = (params) => request.get('/api/org/view_log_report', { params })

// 翼绘个人中心
export const getAIUserCenter = (params) => yihuiRequest.get('/api/user/center', { params })

