import request from '@/utils/request'

// get 请求模板
export function getSign(query) {
    return request({
        url: 'https://lib-api.yiihuu.com/api/get_sign',
        // url: '/api/get_sign',
        method: 'get',
        params: query
    })
}