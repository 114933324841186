import Cookies from 'js-cookie'

const TokenKey = 'yiihuu_sso'

const TokenNick = "nick"
export function getToken() {

  return Cookies.get(TokenKey)
  return "ce96e7f39e25d3381ecdbe5534b3a870479e937d"
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export function getNick() {
  return Cookies.get(TokenNick)
  return "用户名"
}

export function setNick(name) {
  return Cookies.set(TokenNick, name)
}

export function removeNick() {
  return Cookies.remove(TokenNick)
}

