<template>
  <div class="policy_wrap" :style="{ left: placeLeft }">
    <span class="policy_wrap_tips">以上内容均由AI生成，仅供参考和借鉴 </span>
    &nbsp;&nbsp;
    <a target="_blank" href="https://ai.yiihuu.com/agreement/">用户协议</a
    >&nbsp;|&nbsp;<a target="_blank" href="https://ai.yiihuu.com/privacypolicy/"
      >隐私政策</a
    >&nbsp;|&nbsp;<a target="_blank" href="https://ai.yiihuu.com">关于翼绘AI</a
    >&nbsp;|
    <div class="yihui_ai">
      Guangdong-YihuiAI-20240314
      <i class="el-icon-arrow-up"></i>
      <div class="number ta3">
        <a
          href="https://beian.cac.gov.cn/#/searchResult"
          target="_blank"
          class="ta3"
        >
          网信算备440106026876101240011号
        </a>
      </div>
    </div>
    &nbsp;&nbsp;
    <div>
      Copyright © 2011-2025 广州冠岳网络科技有限公司版权所有&nbsp;|&nbsp;<a
        target="_blank"
        href="http://beian.miit.gov.cn"
        >粤ICP备09217733号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyWrap",
  props: {
    placeLeft: {
      default: "50%",
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.policy_wrap {
  position: absolute;
  bottom: 10px;
  transform: translateX(-50%);
  text-align: center;
  font-size: 9px;
  color: #bdbdbf;
  line-height: 12px;
  white-space: nowrap;

  .policy_wrap_tips {
    color: #9da4bb;
  }

  a {
    transition: all 0.3s;
    color: #bdbdbf;
    text-decoration: unset;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }
  .yihui_ai {
    position: relative;
    display: inline-block;
    cursor: default;
    &:hover {
      .number {
        opacity: 1;
        bottom: 100%;
        z-index: 1;
      }
    }
    i{
      font-size: 12px;
    }
    .number {
      opacity: 0;
      width: -webkit-max-content;
      position: absolute;
      bottom: -100%;
      left: 50%;
      transform: translate(-50%);
      padding: 6px;
      background: #fff;
      border-radius: 10px;
      z-index: -1;
      a {
        color: #bdbdbf;
        border-radius: 6px;
        padding: 0 6px;
        background: transparent;
        font-size: 10px;
        line-height: 18px;
        display: block;
        &:hover {
          background: #f4f6f9;
        }
      }
    }
  }
}
</style>
